import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import LeftArrow from '../../assets/icons/TAM-icon-left-arrow.svg'
import { device } from '../../theme/device'

const StyledLink = styled((props) => <Link {...props} />)`
  color: ${({ theme }) => theme.colors.secondary};
  text-decoration: none;
  margin: ${(props) => (props.margin ? props.margin : '0 0 12px 0')};
  cursor: pointer;
  
`

const WrapperButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all .2s ease-in-out;
  cursor: pointer;
  margin-right: 30px;
  @media ${device.desktop} {
    margin-right: 0px;
    
  }
  
`
const WrapperArrow = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: ${({ right }) => (right ? 'auto' : '-31px')};
  right: ${({ right }) => (right ? '-31px' : 'auto')};
  top: 40%;
  transform: translateY(-50%);
  &::before { 
    content: "";
    z-index: -1;
    border-color: ${({ theme }) => theme.colors.primary};
    border-width: 1px;
    border-style: solid;
    opacity: 30%;
    position: absolute;
    width: 57px;
    height: 57px;
    border-radius: 50%;
    transition: all .2s ease-in-out;
  }
  &:hover {
    &::before { 
      transform: scale(1.2);

    }
  }
  @media ${device.desktop} {
    left: ${({ right }) => (right ? 'auto' : '-35px')};
    right: ${({ right }) => (right ? '-35px' : 'auto')};
    &::before { 
      opacity: 30%;
      width: 76px;
      height: 76px;
    }
  }
`
const TextButton = styled.p`
  position: relative;
  white-space: nowrap;
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.font.gorditaM};
  @media ${device.desktop} {
    font-size: ${({ theme }) => theme.fontSize.base};
   
  }
`

const LeftArrowStyle = styled(LeftArrow)`
  fill: ${({ theme }) => theme.colors.primary};
  transform: ${({ toright }) => (toright ? 'rotate(0)' : 'rotate(180deg)')}; 
  width: 21px; 
  height: 21px;
  @media ${device.desktop} {
    width: 24px; 
    height: 24px;
   
  }
`
const isBrowser = typeof window !== 'undefined'

const Button = (props) => <WrapperButton onClick={props.onPress}>
  <TextButton>
    {props.children}
    <WrapperArrow right={props.right}>
      <LeftArrowStyle toright={props.toright}/>
    </WrapperArrow>
  </TextButton>
</WrapperButton>

const ButtonMore = (props) => {
  if (props.back && isBrowser) {
    return (
      <Button {...props} onPress={() => window.history.back()}>
        {props.children}
      </Button>
    )
  }
  if (!props.link) {
    return (
      <Button {...props} >
        {props.children}
      </Button>
    )
  }
  return (
    <StyledLink to={props.link}>
      <Button {...props}>
        {props.children}
      </Button>
    </StyledLink>
  )
}

export default ButtonMore
