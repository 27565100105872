import _ from 'lodash'
import React from 'react'
import './card-address-style.scss'
import { withTrans } from '../../i18n/withTrans'
import IconLocation from '../../assets/icons/TAM-icon-location.svg'
import IconPhone from '../../assets/icons/TAM-icon-phone.svg'
import IconEmail from '../../assets/icons/TAM-icon-email.svg'
import IconUser from '../../assets/icons/TAM_icon_user.svg'

const CardAddress = ({ info }) => (

  <div className="card-address-container">
    {/* <div>
      <div className="card-address-tag">
        <span>
          { t(`map.typesOffices.${_.get(info, 'acf.office_section.type')}`)}
        </span>
      </div>
    </div> */}
    <div className="card-address-header">
      <h3>{_.get(info, 'title.rendered')}</h3>
    </div>
    <div className="card-address-body">
      <table>
        <tbody>

          <tr>
            <td><IconLocation width={24} /></td>
            <td>{_.get(info, 'acf.office_section.full_address')}</td>
          </tr>
          <tr>
            <td><IconPhone width={24} /></td>
            <td><a href={`tel:${_.get(info, 'acf.office_section.phone_no')}`}>{_.get(info, 'acf.office_section.phone_no')}</a></td>
          </tr>
          {_.get(info, 'acf.office_section.contact_person') && (
            <tr>
              <td><IconUser width={24} heigth={20} /></td>
              <td>{_.get(info, 'acf.office_section.contact_person')}</td>
            </tr>
          )}
          {_.get(info, 'acf.office_section.email') && (

            <tr>
              <td><IconEmail width={24} /></td>
              <td><a href={`mailto:${_.get(info, 'acf.office_section.email')}`}>{_.get(info, 'acf.office_section.email')}</a></td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>
)

export default withTrans(CardAddress)
