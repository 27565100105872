/* eslint-disable max-len */
import React, { useRef, useEffect, useState } from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { isTablet } from 'react-device-detect'
import Helmet from 'react-helmet'
import { gsap } from 'gsap'
import './video-home-style.scss'
import noLimits from '../../assets/videos/No_Limits_Video_two.mp4'
import noLimitisGif from '../../assets/videos/gifs/No_Limits_Video.gif'
import imgMaskMobile from '../../assets/images/tam_mobile_no-limits_v2.png'
import imgMaskDesktop from '../../assets/images/tam_desktop_no-limits_v2.png'

const VideoHome = () => {
  const videoRef = useRef(null)
  const breakpoints = useBreakpoint()
  const isBrowser = typeof window !== 'undefined'
  const [addBodyClass, setAddBodyClass] = useState(false)
  const frame = useRef(null)
  const frameDesk = useRef(null)
  const cont = useRef(null)
  function fadeOut() {
    const { current: tl } = gsap.to(cont.current,
      {
        opacity: 0,
        display: 'none',
        duration: 0.8,
        onComplete: () => {
          setAddBodyClass(false)
        },
      })
  }

  function dispatchAnimation() {
    setAddBodyClass(true)
    const { current: tl } = gsap.to(frame.current, {
      scale: 160,
      duration: 1.8,
      delay: 3,
      onComplete: () => fadeOut(),
    })
  }

  function dispatchAnimationDesktop() {
    setAddBodyClass(true)
    const { current: tl } = gsap.to(frameDesk.current, {
      scale: 140,
      duration: 3,
      delay: 2,
      onComplete: () => fadeOut(),
    })
  }

  // useEffect(() => {
  //   if (!breakpoints.xs && videoRef.current && videoRef.current.readyState === 0) {
  //     dispatchAnimation()
  //   }
  // }, [videoRef.current && videoRef.current.readyState])

  useEffect(() => {
    if (breakpoints.xs) {
      dispatchAnimation()
    }
    dispatchAnimationDesktop()
  }, [])

  return (

    <div ref={cont} className="video-home-container" id="video">
      <Helmet

        bodyAttributes={{
          class: addBodyClass ? 'overlay' : '',
        }}
      />
      {!breakpoints.xs ? (

        <div ref={frameDesk} className="backgorund-video-image">
          <img alt="clouds" src={imgMaskDesktop} />
        </div>

      ) : (
        <div ref={frame} className="backgorund-video-image">
          <img alt="clouds" src={imgMaskMobile} />
        </div>
      )}
    </div>

  )
}

export default VideoHome
