/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import classNames from 'classnames'
import { useWindowDimensions } from '../../helpers'
import { useOffices } from '../../hooks/use-offices'
import useLocation from '../../hooks/use-location'

import { sortByCities } from '../../helpers/sort-by-country'
import MapWithAMarkerClusterer from './componets/map-with-marker/map-with-marker'
import './map-style.scss'
import Address from './componets/address/addresss'
import { withTrans } from '../../i18n/withTrans'

const Map = ({
  address,
  filter,
  isHome = false,
  slug,
  t,
}) => {
  const [officesSorted, setOfficesSorted] = useState([])
  const [isChina, setIsChina] = useState(false)
  const [officesFilterByContinent, setOfficesFilterByContinent] = useState([])
  const [selectedOffice, setSelectedOffice] = useState({})
  const { offices } = useOffices()
  const size = useWindowDimensions()
  const breakpoints = useBreakpoint()
  const width = _.get(size, 'width', 0)

  const onChangeMarker = (c) => {
    setSelectedOffice(c.mainOffice)
  }
  const locationIP = useLocation()
  useEffect(() => {
    if (!_.isEmpty(filter)) {
      const officesFiltered = _.filter(officesSorted, (office) => office.continent === filter)
      setOfficesFilterByContinent(officesFiltered)
      setSelectedOffice(officesFiltered[0])
    } else {
      setOfficesFilterByContinent(officesSorted)
      // setSelectedOffice(officesSorted[0])
      if (isHome && !breakpoints.xs) {
        setSelectedOffice({})
      } else {
        setSelectedOffice(officesSorted[0])
      }
    }
  }, [filter, officesSorted])

  useEffect(() => {
    const result = sortByCities(offices)
    setOfficesSorted(result)
    setSelectedOffice(_.get(result[0], 'mainOffice'))
  }, [offices])

  const handleSelectOfficeInMarket = (office) => {
    setSelectedOffice(office)
  }

  const handleDeselectOffcies = () => {
    setSelectedOffice({})
  }
  useEffect(() => {
    if (locationIP === 'CN') {
      setIsChina(true)
    } else {
      setIsChina(false)
    }
  }, [locationIP])

  function groupBy(arr, criteria) {
    const newObj = arr.reduce((acc, currentValue) => {
      if (!acc[currentValue[criteria]]) {
        acc[currentValue[criteria]] = []
      }

      acc[currentValue[criteria]].push(currentValue)
      return acc
    }, {})
    return newObj
  }
  const orderByColumnsContinent = officesSorted && _.orderBy(_.map(groupBy(officesSorted, 'continent'), (value, key) => ({
    name: value[0].continent_name,
    slug: key,
    off: value,
  })), { slug: 'partners' })
  const link = (item) => (slug ? `/${slug}/#${item}` : `/#${item}`)
  return (
    <div className="container-map">
      <div className='map-columns'>
        {
          isHome && orderByColumnsContinent && _.map(orderByColumnsContinent, (item) => (
            <div className={item.slug === 'partners' ? 'column grey' : 'column'} key={item.name}>
              <h4>{item.name}</h4>
              <div className='column-box'>
                {
                  _.map(item.off, (country) => (
                    <div key={country.id} className="card-city">
                      <AnchorLink to={link('box-map')} title={country}>
                        <p onClick={() => onChangeMarker(country)} className={
                          classNames({
                            'title-map': true,
                            'title-map-selected': country.title === selectedOffice.title,
                          })}
                        >{_.get(country, 'title')}</p>
                      </AnchorLink>
                      <p className="number-offices">
                        {_.get(country, 'offices').length} {t('map.offices', { count: _.get(country, 'offices', []).length })}
                        {/* {country.isHeadquarter && <span style={{ marginLeft: '10px' }} className="tag-market">{t('map.typesOffices.headquarter')}</span>} */}
                      </p>
                    </div>
                  ))
                }
              </div>

            </div>
          ))
        }

      </div>
      <div className="content-map">
        {
          !breakpoints.xs && !isHome && (
            <aside className="aside-map">
              {
                officesFilterByContinent && _.map(officesFilterByContinent, (country) => (
                  <div key={country.id} className="card-city">
                    <p onClick={() => onChangeMarker(country)} className={
                      classNames({
                        'title-map': true,
                        'title-map-selected': country.title === selectedOffice.title,
                      })}
                    >{_.get(country, 'title')}</p>
                    <p className="number-offices">
                      {_.get(country, 'offices').length} {t('map.offices', { count: _.get(country, 'offices', []).length })}
                      {/* {country.isHeadquarter && <span style={{ marginLeft: '10px' }} className="tag-market">{t('map.typesOffices.headquarter')}</span>} */}
                    </p>

                  </div>
                ))
              }
            </aside >
          )
        }

        <main className="main-map" >

          {!isChina ? (
            !address ? (
              <div className="box-map" id='box-map'>
                <MapWithAMarkerClusterer
                  loadingElement={<div style={{ height: '100%' }} />}
                  containerElement={<div style={{ height: width < 1440 ? '815px' : '100vh' }} />}
                  mapElement={<div style={{ height: '100%' }} />}
                  selectedOffice={selectedOffice}
                  offices={offices}
                  onMarkerClustererClick={() => { }}
                  width={width}
                  t={t}
                  isHome={isHome}
                  onDeselectOffcices={handleDeselectOffcies}
                  onSelectOfficeInMarket={handleSelectOfficeInMarket}
                />
              </div>
            ) : (
              selectedOffice && <Address
                selectedOfficeCard={selectedOffice}
              />
            )) : (
            selectedOffice && <Address
              selectedOfficeCard={selectedOffice}
            />
          )}
        </main >
      </div >
    </div >
  )
}

export default withTrans(Map)
