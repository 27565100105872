import _ from 'lodash'
import React from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import { Wrapper } from '../page-elements'
import { device } from '../../theme/device'
import { useWindowDimensions } from '../../helpers'

const ButonPlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px; 
  height: 48px;
  border-radius: 60px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary};
  p {
    font-size: 12px;
    font-family: ${({ theme }) => theme.font.gorditaM};
    line-height: 18px;
  }

  @media ${device.tablet} {
    width: 120px; 
    height: 120px;
    p {
      font-size: 16px;
      font-family: ${({ theme }) => theme.font.gorditaM};
      line-height: 24px;
    }
  }

`

const Video = ({ videoInfo }) => {
  const size = useWindowDimensions()
  const width = _.get(size, 'width', 0)
  const { url } = videoInfo
  const id = url && url.split('v=')[1]
  return (
    <Wrapper widthD="1034px" style={{ height: 'inherit' }}>
      <ReactPlayer
        url={url}
        width="100%"
        height={width > 768 ? '582px' : '210px' }
        playing
        playIcon={<ButonPlay><p>Play</p></ButonPlay>}
        light={`https://img.youtube.com/vi/${id}/hqdefault.jpg`}
      />
    </Wrapper>

  )
}

export default Video
