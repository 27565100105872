import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Wrapper } from '../page-elements'

const BigWord = ({
  icon,
  iconHover,
  widthD,
  height,
  link,
}) => {
  const Icon = icon
  const IconHover = iconHover
  const [isHover, setIsHover] = useState(false)
  const handleBoxOut = () => {
    setIsHover(false)
  }

  const handleBoxIn = () => {
    setIsHover(true)
  }

  return (
    <Link
      style={{
        height,
        width: '100%',
      }}
      to={`/what-we-offer/${link}/`}>
      <Wrapper
        marginD="0"
        style={{
          height,
          width: '100%',
          maxWidth: widthD,
        }}
        onMouseEnter={handleBoxIn}
        onMouseLeave={handleBoxOut}>
        {
          !isHover ? (
            <IconHover style={{ stroke: '#7B7B7B', position: 'absolute' }}/>
          ) : (
            <Icon style={{ fill: '#e20613', position: 'absolute' }}/>

          )
        }
      </Wrapper>
    </Link>
  )
}

export default BigWord
