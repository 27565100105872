/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import { helmetJsonLdProp } from 'react-schemaorg'
import React from 'react'
import { Helmet } from 'react-helmet'

const Seo = ({
  description,
  lang,
  meta,
  title,
  schema,
  type,
}) => {
  const metaDescription = description
  const defaultTitle = ''

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: type,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: '',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ].concat(meta)}
      script={[
        helmetJsonLdProp(schema),
      ]}
    />
  )
}

Seo.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
  schema: {},
  type: '',
  title: '',
}

export default Seo
