import React from 'react'
import './full-image.scss'

const FullImage = ({
  image,
}) => (
  <div className="full-image-container">
    <img src={image}/>
  </div>
)

export default FullImage
