import _ from 'lodash'
import React from 'react'
import { Link } from 'gatsby'
import {
  GoogleMap,
  Marker,
  OverlayView,

} from '@react-google-maps/api'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
// import '../../map-style.scss'
import imgMarker from '../../../../assets/images/cluster/dot.png'
import IconClose from '../../../../assets/icons/TAM-icon-close.svg'
import IconEmail from '../../../../assets/icons/TAM-icon-email.svg'
import IconUser from '../../../../assets/icons/TAM_icon_user.svg'
import demoFancyMapStyles from '../../mapStyling.json'

const getPixelPositionOffset = (offsetWidth, offsetHeight, labelAnchor) => ({
  x: offsetWidth + labelAnchor.x,
  y: offsetHeight + labelAnchor.y,
})

const MapWithAMarkerClusterer = ({
  selectedOffice,
  onSelectOfficeInMarket,
  // onMarkerClustererClick,
  onDeselectOffcices,
  offices,
  width,
  isHome,
  t,
}) => {
  const breakpoints = useBreakpoint()
  // function createKey(location, idx) {
  //   return location.lat + location.lng + idx
  // }

  let xPosition
  let yPosition

  switch (true) {
    case width > 1024:
      xPosition = -462
      yPosition = -425
      break
    case width > 764:
      xPosition = -475
      yPosition = -425
      break
    default:
      xPosition = -476
      yPosition = -425
      break
  }

  const getZoom = (country) => {
    let zoom = 9

    switch (true) {
      case country === 'Hong Kong':
        zoom = 9
        return zoom
      case _.isEmpty(selectedOffice):
        zoom = 2
        return zoom

      default:
        zoom = 4
        return zoom
    }
  }

  const getCenter = (office) => {
    if (!_.isEmpty(office)) {
      return {
        lat: office.lat,
        lng: office.lng,
      }
    }
    return {
      lng: 12.396983,
      lat: 33.7810075,
    }
  }

  function getTitle(obj) {
    const country = _.get(obj, 'continent.country.name')
    const city = _.get(obj, 'continent.country.city.name')

    if (_.get(obj, 'continent.country.city.slug') === 'hong-kong-sar') return city

    return `${country}`
  }

  const organizeMainOffice = (office) => ({
    id: _.get(office, 'id'),
    lat: _.toNumber(_.get(office, 'acf.office_section.geographic_coordinate.latitude')),
    lng: _.toNumber(_.get(office, 'acf.office_section.geographic_coordinate.longitude')),
    number: 1,
    country: _.get(office, 'continent.country.name'),
    countrySlug: _.get(office, 'continent.country.slug'),
    title: getTitle(office),
    type: _.get(office, 'type'),
    offices,
    img: _.get(office, 'continent.country.city.featured_image.url'),
    alt: _.get(office, 'continent.country.city.featured_image.alt'),
    description: _.get(office, 'continent.country.description'),
    continent: _.get(office, 'continent.slug'),
    contact_person: _.get(office, 'acf.office_section.contact_person'),
    email: _.get(office, 'acf.office_section.email'),

  })
  return (

    <GoogleMap
      zoom={getZoom(_.get(selectedOffice, 'country'))}
      mapContainerStyle={{
        height: width >= 420 ? '70vh' : '100vh',
        maxHeight: width >= 420 ? '800px' : '100vh',
      }}
      options={{
        styles: demoFancyMapStyles,
        disableDefaultUI: true,
        zoomControl: true,
        keyboardShortcuts: false,
        draggable: true,
      }}
      // mapContainerStyle={ demoFancyMapStyles }
      center={getCenter(selectedOffice)}
    // center={{ lat: -28.024, lng: 140.887 }}
    >
      {
        (breakpoints.sm || !_.isEmpty(selectedOffice)) && (
          <OverlayView
            key='mwl'
            position={{ lat: selectedOffice?.lat, lng: selectedOffice?.lng }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={(x, y) => getPixelPositionOffset(x, y,
              {
                x: xPosition,
                y: yPosition,
              })}
          >
            <div
              className="marker-map"
            >
              <div className="head-marker">
                {/* <div className="img-marker">
                    <img src={_.get(selectedOffice, 'img')} alt={_.get(selectedOffice, 'alt')}/>
                  </div> */}
                {
                  !breakpoints.xs && (
                    <div onClick={onDeselectOffcices} className="closed-market">
                      <IconClose width="15" />
                    </div>
                  )
                }
                <div className="content-head-marker">
                  <p>{_.get(selectedOffice, 'title')}</p>
                  {selectedOffice?.isHeadquarter && !isHome && <span className="tag-market">{t('map.headquarter')}</span>}
                  {!selectedOffice?.isHeadquarter && !isHome && <span className="tag-market">{_.get(selectedOffice, 'type')}</span>}
                </div>
              </div>
              <div className="content-market">
                <p>{_.get(selectedOffice, 'mainOffice.alt', selectedOffice?.alt)}</p>
                {_.get(selectedOffice, 'contact_person') && (
                  <p className='content-market__person'>
                    <span><IconUser width={24} heigth={20} /></span>
                    <span>{_.get(selectedOffice, 'contact_person')}</span>
                  </p>
                )}
                {_.get(selectedOffice, 'email') && (

                  <p className='content-market__email'>
                    <span><IconEmail width={24} /></span>
                    <span><a href={`mailto:${_.get(selectedOffice, 'email')}`}>{_.get(selectedOffice, 'email')}</a></span>
                  </p>
                )}
              </div>
              <div className="footer-market">
                <Link
                  to="/connect-with-us"
                  state={{
                    continent: _.get(selectedOffice, 'continent'),
                  }}>
                  <span>
                    {t('map.contact')}
                  </span>
                </Link>
              </div>
            </div>
          </OverlayView>
        )
      }

      {
        breakpoints.sm ? (
          <Marker
            options={{
              icon: imgMarker,
              anchorPoint: false,
            }}
            position={{
              lat: selectedOffice?.lat,
              lng: selectedOffice?.lng,
            }}
          />
        )
          : (
            <>
              {offices && offices.map((marker, idx) => {
                const location = {
                  lat: _.get(marker, 'acf.office_section.geographic_coordinate.latitude'),
                  lng: _.get(marker, 'acf.office_section.geographic_coordinate.longitude'),
                }
                return (
                  <Marker
                    key={location + idx}
                    options={{
                      icon: imgMarker,
                      anchorPoint: false,
                    }}
                    onClick={() => onSelectOfficeInMarket(organizeMainOffice(marker))}
                    position={{
                      lat: _.toNumber(location?.lat),
                      lng: _.toNumber(location?.lng),
                    }}
                  />
                )
              })}
            </>
          )}

    </GoogleMap>

  )
}

export default MapWithAMarkerClusterer
