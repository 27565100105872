import React from 'react'
import classNames from 'classnames'
import './no-image-style.scss'
import LogoTam from '../../assets/icons/TAM-logo-tam.svg'

const NoImage = ({ related }) => (
  <div className={
    classNames({
      'no-image-container': true,
      related,
    })
  }>
    <div className='wrapper-no-image'>
      <LogoTam fill="white" height="auto" width="100%" />

    </div>
  </div>
)

export default NoImage
