import _ from 'lodash'
import React from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import { device } from '../../theme/device'
import {
  Wrapper, Subtitle, H2, P,
} from '../page-elements'

import Circle from '../circle/circle'
import './block-numbers-style.scss'

const BlockNumberWrapper = styled((props) => <Wrapper {...props} />)` 
  magin-bottom: 0px;
  @media ${device.tablet} {
    margin-bottom: 50px;
  }
  @media ${device.desktop} {
    margin-bottom: 0;

  }

`

const DataWrapper = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 40px;
  @media ${device.desktop} {
    padding: 0 80px;

  }
`

const DataNumber = styled.p`
  padding-top: 10px;
  font-size: 80px;
  letter-spacing: -6px;
  font-family: ${({ theme }) => theme.font.gordita};
  line-height: 80px;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  color: #112530;
  opacity: 0.25;
  margin: 0;
  .slick-center & {
    color: ${(props) => props.theme.colors.primary};
    opacity: 1;
  }
  @media ${device.desktop} {
    font-size: 136px;
    line-height: 136px;
  }
`
const DataTitle = styled.p`
  text-transform: uppercase;
  font-size: 11px;
  color: #7B7B7B;
  font-family: Manrope;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 14px;
  @media ${device.desktop} {
    font-size: 13px;

    line-height: 24px;
  }
`

const WrapperNumbers = styled.div`
  position: relative;
  max-width: 1240px;
  margin: 60px auto 120px;

  width: 100%;
  &:before {
    content: '';
    z-index: 1000;
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: 170px;
    background: rgb(255,255,255);
    background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 70%);
  }
  &:after {
    content: '';
    z-index: 1000;
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 170px;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 70%);
  }

  @media ${device.tablet} {
    margin: 60px auto 60px;
   
  }
  @media ${device.desktop} {
    margin: 60px auto;
    &:before {

      width: 300px;
      height: 180px;
    }
    &:after {
      width: 300px;
      height: 180px;
    }
  }

`

const BlockNumbers = ({
  data,
  statistics,
  t,
  widthBackground,
  title,
}) => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 500,
    speed: 1500,
    slidesToShow: 2,
    slidesToScroll: 1,
    className: 'center',
    centerMode: true,
    arrows: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 764,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          variableWidth: true,
        },
      },
    ],
  }

  return (

    <BlockNumberWrapper

      flexDirectionD="column"
      displayM="box"
      heightD="900px"
      heightM="auto"
    >
      <Wrapper
        heightM="auto"
        paddingM="80px 0 0"
        heightD="500px"
        widthD="1920px"
        flexDirectionD="column"
        style={{
          overflow: 'hidden',
        }}
      >
        {/* {widthBackground && <Background />} */}
        {!widthBackground && <Circle />}
        <Wrapper
          widthD="1440px"
          marginM="0px 0 0"
          flexDirectionD="column"

        >
          <Subtitle>{title}</Subtitle>
          <Wrapper
            style={{ textAlign: 'center' }}
            widthM="80%"
            marginM="20px 0 0"
            marginD="16px 0 0"
            paddingM="0 25px"
            widthD="820px">
            <H2
              fontSize="2xll"
              lineHeight="4xl"
              fontSizeD="4xl"
              lineHeightD="6xl"
            >{_.get(data, 'section_title')}</H2>
          </Wrapper>
          {widthBackground
           && (
             <Wrapper
               widthM="80%"
               marginM="24px 0"
               widthD="740px"
               marginD="32px 0">
               <P style={{ textAlign: 'center', lineHeight: '28px' }}>{_.get(data, 'section_description', _.get(data, 'description', undefined))}</P>
             </Wrapper>
           )}
        </Wrapper>

      </Wrapper>
      <div id="company"></div>
      <WrapperNumbers>

        <Slider {...settings}>
          {

            data && _.map(statistics, (statistic, idx) => (
              <div key={idx}>
                <DataWrapper >
                  <DataNumber>{statistic.value}</DataNumber>
                  <DataTitle>{statistic.title}</DataTitle>
                </DataWrapper>
              </div>
            ))

          }
        </Slider>
      </WrapperNumbers>

    </BlockNumberWrapper>

  )
}

export default BlockNumbers
