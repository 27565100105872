import _ from 'lodash'
import React from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import styled from 'styled-components'
import { Wrapper, Subtitle, H2 } from '../page-elements'
import ButtonMore from '../button-more'
import { useWindowDimensions } from '../../helpers'
import { device } from '../../theme/device'

const PartnersWrapper = styled((props) => <Wrapper {...props} />)` 

  text-align: center;
  flex-wrap: wrap;
  @media ${device.tablet} {
    margin-bottom: 110px;
    margin-top: 50px;
  }
  @media ${device.desktop} {
    margin-bottom: 110px;
    margin-top: 50px;

  }

`

const OurPathers = ({
  porfolio,
  partners,
  title,
  t,
}) => {
  const size = useWindowDimensions()
  const width = _.get(size, 'width', 0)
  const breakpoints = useBreakpoint()
  return (

    <Wrapper
      flexDirectionD="column"
      displayM="box"
      paddingD="160px 0"
      paddingM="96px 0"
    >
      <Wrapper
        widthD="1920px"
        flexDirectionD="column"
      >
        <Wrapper
          widthD="1240px"
          flexDirectionD="column"
        >
          <Subtitle>{title}</Subtitle>
          <Wrapper
            style={{ textAlign: 'center' }}
            widthM="475px"
            widthD="925px"
            marginD="12px 0 74px 0">
            <H2
              fontSize="2xll"
              lineHeight="4xll"
              fontSizeD="4xl"
              lineHeightD="6xl"

            >{t('home.oursTitle')}</H2>
          </Wrapper>

        </Wrapper>
        <PartnersWrapper
          flexDirectionM="row"
          widthD="920px"
          paddingM="48px 0 0"
          justifyContentD="flex-start"
        >
          {
            _.map(partners, (partner) => (
              <Wrapper
                style={{
                  flex: breakpoints.xs ? '1 1 50%' : '1 1 25%',
                  maxWidth: breakpoints.xs ? '50%' : '25%',
                }}
                marginD="0 0 84px 0"
                marginM="0 0 51px 0"
                key={partner.id}>
                <Wrapper widthD="136px" widthM="88px">
                  <img style={{ width: '100%' }}src={_.get(partner, 'featured_image.url')} />

                </Wrapper>
              </Wrapper>
            ))
          }

        </PartnersWrapper>
        {
          porfolio && (
            <Wrapper marginD="72px 0 0 0">
              <ButtonMore
                right
                toright={'true'}
                link="/our-clients/"
              >{t('home.view')}
              </ButtonMore >
            </Wrapper>

          )
        }
      </Wrapper>

    </Wrapper>
  )
}

export default OurPathers
