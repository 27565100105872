import React from 'react'
import _ from 'lodash'
import CardAddress from '../../../card-address/card-address'

const Address = ({
  selectedOfficeCard,
}) => {
  const offices = _.get(selectedOfficeCard, 'offices', [])
  const officesSorted = offices.sort((a) => (_.get(a, 'acf.office_section.type') === 'headquarter' ? -1 : 1))
  return (
    <div className="address-container">
      <div className="address-header">
        <h2>{_.get(selectedOfficeCard, 'title')}</h2>
      </div>
      <div className="address-box">
        {
          selectedOfficeCard && _.map(officesSorted, (address) => <CardAddress info={address} key={address.id}/>)
        }
      </div>
    </div>
  )
}

export default Address
